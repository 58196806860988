import React from "react";
import SEO from "../components/SEO";
import Layout from "../components/Layout";
import Container from "../components/Container";
import LeftArrow from "../svgs/icons/left-arrow.svg";
import { FormattedHTMLMessage, Link } from "gatsby-plugin-intl";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { css } from "@emotion/core";
import { Desktop, Tablet } from "../constants/responsive";

const BlogPost = ({ pageContext }) => {
  const {
    post: {
      title,
      date,
      description: { description },
      content,
    },
  } = pageContext;

  // const intl = useIntl();

  const options = {
    renderNode: {
      // eslint-disable-next-line react/display-name
      [INLINES.ENTRY_HYPERLINK]: ({
        data: {
          target: { slug, title },
        },
      }) => <Link to={slug}>{title}</Link>,
      // eslint-disable-next-line react/display-name
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        return (
          <div className="img-wrapper">
            <img
              src={node.data.target.file.url}
              css={css`
                width: 100%;
                border: 1px solid black;
                padding: 0px;
              `}
            />
          </div>
        );
      },
    },
  };

  return (
    <Layout>
      <SEO title={`${title} - Rice Robotics`} description={description} />
      <Container>
        <div
          css={css`
            display: flex;
            flex-direction: column;
          `}
        >
          <h1>{title}</h1>
          <h2
            css={css`
              margin-bottom: 10px;
              font-weight: 300;
            `}
          >
            {date}
          </h2>
          <div
            css={css`
              h1,
              h2,
              h3,
              h4,
              h5,
              h6 {
                margin-bottom: 0px;
                display: block;
              }
              h1,
              h2 {
                font-weight: bold;
              }
              p {
                margin-bottom: 20px;
                display: block;
              }
              * {
                margin-bottom: 0px;
                // padding: 0px 10px;
              }
              a,
              a:active,
              a:visited {
                padding: 0px;
                color: #9e9e9e;
                font-weight: bold;
                transition: all 0.2s ease;
              }
              a:hover {
                color: #616161;
              }
              .img-wrapper {
                width: 100%;
                display: inline-block;
                margin-bottom: 15px;
                margin-top: 5px;
              }

              padding: 0px 10px;

              margin-top: 20px;
              margin-left: -10px;
              margin-right: -10px;
              @media (min-width: 400px) {
                .img-wrapper {
                  width: 50%;
                }
              }
              ${Tablet} {
                margin-top: 40px;
                margin-left: -20px;
                margin-right: -20px;
                padding: 0px 20px;
                .img-wrapper {
                  margin-bottom: 25px;
                  margin-top: 15px;
                }
              }
            `}
          >
            {content && renderRichText(content, options)}
          </div>
        </div>
        <div
          css={css`
            width: 220px;
            margin-left: auto;
            margin-right: auto;
          `}
        >
          <Link to="/blog">
            <div
              css={css`
                display: flex;
                align-items: center;
                user-select: none;
                width: 160px;
                height: 45px;
                border: 2px solid black;
                border-radius: 10px;
                justify-content: center;
                color: black;
                cursor: pointer;
                transition: all 0.2s ease;
                margin-top: 15px;
                margin-bottom: 60px;
                margin-left: auto;
                margin-right: auto;

                svg {
                  path {
                    fill: black;
                    transition: all 0.2s ease;
                  }
                }
                &:hover {
                  background: black;
                  color: white;
                  path {
                    fill: white;
                  }
                }
                ${Tablet} {
                  width: 220px;

                  margin-top: 15px;

                  margin-bottom: 60px;
                  height: 40px;
                }
                ${Desktop} {
                  margin-top: 20px;

                  margin-bottom: 80px;
                  height: 50px;
                }
              `}
            >
              <LeftArrow
                css={css`
                  height: 10px;
                  width: 10px;

                  margin-right: 5px;
                  ${Tablet} {
                    height: 12px;
                    width: 12px;
                  }
                  ${Desktop} {
                    height: 15px;
                    width: 15px;
                    margin-right: 10px;
                  }
                `}
              />
              <h3>
                <FormattedHTMLMessage id="blog.back" />
              </h3>
            </div>
          </Link>
        </div>
      </Container>
    </Layout>
  );
};

export default BlogPost;
